import '../App.css';
import TextareaAutosize from 'react-textarea-autosize';
import React from 'react';

/**
 * Renders the DisplayInterview component.
 *
 * @param {Object} args - The arguments object.
 * @returns {JSX.Element} The JSX element representing the DisplayInterview component.
 */
const DisplayInterview = (args) => {

    /**
     * Handles the change in notes for a specific section, type, and number.
     * Updates the candidate information accordingly.
     * 
     * @param {string} section - The section of the candidate information (e.g., 'general', 'technical', 'lackingQualities').
     * @param {string} type - The type of information to be updated (e.g., 'question', 'notes', 'answer').
     * @param {number} num - The index of the question or information to be updated.
     * @param {Event} event - The event object representing the change in notes.
     */
    const handleNotesChange = (section, type, num, event) => {
        let newCandidateInfo = {...args.candidateInfo};
        switch(section){
            case 'general':
                type === 'question' ? newCandidateInfo.general_questions[num].question = event.target.value : type === 'notes' ? newCandidateInfo.general_questions[num].notes = event.target.value : newCandidateInfo.general_questions[num].answer = event.target.value;
                break
            case 'technical':
                type === 'question' ? newCandidateInfo.technical_questions[num].question = event.target.value : type === 'notes' ? newCandidateInfo.technical_questions[num].notes = event.target.value : newCandidateInfo.technical_questions[num].answer = event.target.value;
                break
            case 'lackingQualities':
                newCandidateInfo.lacking_qualities = event.target.value;
                break
            case 'strengths':
                newCandidateInfo.strengths = event.target.value;
                break
            case 'score':
                newCandidateInfo.score = event.target.value;
                break
            case 'assessment':
                newCandidateInfo.assessment = event.target.value;
                break
            case 'linkedCompare':
                newCandidateInfo.linkedCompare = event.target.value;
                break
            case 'overall':
                newCandidateInfo.overall_notes = event.target.value;
                break
            default:
                break
        }
        args.setCandidateInfo(newCandidateInfo);
    }

    const customQuestion = (type) => {
        let newCandidateInfo = {...args.candidateInfo};
        if(type === 'behavioral') {
            newCandidateInfo.general_questions.push({question: '', answer: '', notes: ''});
        } else {
            newCandidateInfo.technical_questions.push({question: '', answer: '', notes: ''});
        }
        args.setCandidateInfo(newCandidateInfo);
    }

    // saves the candidate information and returns to the home page
    const goBack = () => {
        args.handleSaveCandidate({...args.candidateInfo})
        args.setHome(true)
    }

    // Temporary solution to remove Education Level and Communication Skills from candidate Score. Not to be used as a long term solution
    // TODO: Once backend database can be backed-up. Alter the backend prompt that generates score to remove these fields.
    const tempFixScore = (scoreStr) => {
        let scoreArray = scoreStr.split('\n\n');
        let filteredArr = scoreArray.filter((str) => !str.includes('Education Level') && !str.includes('Communication Skills'));
        let returnStr = filteredArr.join('\n\n');
        return returnStr
    }

    return (
        <>
            <div className='w-100 file-upload-info d-flex justify-content-between fixed-top'>
                <p className='m-3 p-0'>
                    {args.candidateInfo.Candidate_Name || "Error Finding Name"} {args.candidateInfo.Job_Title === 'NONE' ? null : `(${args.candidateInfo.Job_Title})`}
                </p>
                <p className='m-3 p-0'>
                    {args.candidateInfo.Job_Description_Title}
                </p>
            </div>
            <button className='fixed-top btn-back' style={{"margin": "80px 0 0 25px", "width":"100px"}} onClick={goBack}>{'<-- Back'}</button>
            <div className='convo-section mx-auto' style={{overflow: "initial"}}>
                <div>

                    <div className='w-100 file-upload-info d-flex justify-content-center mb-3'>
                        <p className='m-3 p-0'>
                            Strengths of Candidate
                        </p>
                    </div>
                    <div id='d-flex justify-content-center'>
                        <TextareaAutosize
                            type="text"
                            className='interview-bubble response-text mx-auto mb-3'
                            value={args.candidateInfo.strengths || ''}
                            onChange={event => handleNotesChange('strengths', 'N/A', 0, event)}
                            onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                    </div>

                    <div className='w-100 file-upload-info d-flex justify-content-center mb-3'>
                        <p className='m-3 p-0'>
                            Skills Lacking
                        </p>
                    </div>
                    <div id='d-flex justify-content-center'>
                        <TextareaAutosize
                            type="text"
                            className='interview-bubble response-text mx-auto mb-3'
                            value={args.candidateInfo.lacking_qualities || ''}
                            onChange={event => handleNotesChange('lackingQualities', 'N/A', 0, event)}
                            onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                    </div>

                    <div className='w-100 file-upload-info d-flex justify-content-center mb-3'>
                        <p className='m-3 p-0'>
                            Candidate Score:
                        </p>
                    </div>
                    <div id='d-flex justify-content-center'>
                        <TextareaAutosize
                            type="text"
                            className='interview-bubble mx-auto response-text mb-3'
                            value={tempFixScore(args.candidateInfo.score) || ''}
                            onChange={event => handleNotesChange('score', 'N/A', 0, event)}
                            onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                    </div>

                    <div className='w-100 file-upload-info d-flex justify-content-center mb-3'>
                        <p className='m-3 p-0'>
                            Resume Assessment
                        </p>
                    </div>
                    <div id='d-flex justify-content-center'>
                        <TextareaAutosize
                            type="text"
                            className='interview-bubble mx-auto response-text mb-3'
                            value={args.candidateInfo.assessment || ''}
                            onChange={event => handleNotesChange('assessment', 'N/A', 0, event)}
                            onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                    </div>

                    <div className='w-100 file-upload-info d-flex justify-content-center mb-3'>
                        <p className='m-3 p-0'>
                            LinkedIn Profile & Resume Comparison
                        </p>
                    </div>
                    <div id='d-flex justify-content-center'>
                        <TextareaAutosize
                            type="text"
                            className='interview-bubble mx-auto response-text mb-3'
                            value={args.candidateInfo.linkedCompare || ''}
                            onChange={event => handleNotesChange('linkedCompare', 'N/A', 0, event)}
                            onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                    </div>

                    <div className='w-100 file-upload-info d-flex justify-content-center mb-3'>
                        <p className='m-3 p-0'>
                            5 Technical Interview Questions
                        </p>
                    </div>
                    {args.candidateInfo.technical_questions.map((text, num) => (
                    <>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-2 m-0 blob-title'>Question {num + 1}:</div>
                                <div className='col-10'>
                                    <TextareaAutosize 
                                        key={`tq.${num}`}
                                        type="text"
                                        className='interview-bubble response-text mb-3'
                                        value={text.question || ''}
                                        onChange={event => handleNotesChange('technical', 'question', num, event)}
                                        onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                                </div>
                                <div className='col-2 m-0 blob-title'>Look For:</div>
                                <div className='col-10'>
                                    <TextareaAutosize 
                                        key={`ta.${num}`}
                                        type="text"
                                        className='interview-bubble query-text mb-3'
                                        value={text.answer || ''}
                                        onChange={event => handleNotesChange('technical', 'answer', num, event)}
                                        onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                                </div>
                                <div className='col-2 m-0 blob-title'>Notes:</div>
                                <div className='col-10'>
                                    <TextareaAutosize 
                                        key={`tn.${num}`}
                                        type="text"
                                        className='interview-bubble notes-text mb-3'
                                        value={text.notes || ''}
                                        onChange={event => handleNotesChange('technical', 'notes', num, event)}
                                        onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                                </div>
                                {num < args.candidateInfo.technical_questions.length -1 ? (
                                    <div className='separator mt-3 mb-5'></div>
                                ): null}
                            </div>
                        </div>
                    </>
                    ))}
                    <div className='w-100 d-flex justify-content-center mb-3'>
                        <button className='btn btn-success' onClick={() => customQuestion('technical')}>Add Custom Technical Question</button>
                    </div>

                    <div className='w-100 file-upload-info d-flex justify-content-center mb-3'>
                        <p className='m-3 p-0'>
                            3 Behavioral Interview Questions
                        </p>
                    </div>
                    {args.candidateInfo.general_questions.map((text, num) => (
                    <>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-2 m-0 blob-title'>Question {num + 1}:</div>
                                <div className='col-10'>
                                    <TextareaAutosize
                                        key={`gq.${num}`}
                                        type="text"
                                        className='interview-bubble response-text mb-3'
                                        value={text.question || ''}
                                        onChange={event => handleNotesChange('general', 'question', num, event)}
                                        onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                                </div>
                                <div className='col-2 m-0 blob-title'>Look For:</div>
                                <div className='col-10'>
                                    <TextareaAutosize 
                                        key={`ga.${num}`}
                                        type="text"
                                        className='interview-bubble query-text mb-3'
                                        value={text.answer || ''}
                                        onChange={event => handleNotesChange('general', 'answer', num, event)}
                                        onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                                </div>
                                <div className='col-2 blob-title'>Notes:</div>
                                <div className='col-10'>
                                    <TextareaAutosize 
                                        key={`gn.${num}`}
                                        type="text"
                                        className='interview-bubble notes-text mb-3'
                                        value={text.notes || ''}
                                        onChange={event => handleNotesChange('general', 'notes', num, event)}
                                        onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                                </div>
                                {num < args.candidateInfo.general_questions.length -1 ? (
                                    <div className='separator mt-3 mb-5'></div>
                                ): null}
                            </div>
                        </div>
                    </>
                    ))}
                    <div className='w-100 d-flex justify-content-center mb-3'>
                        <button className='btn btn-success' onClick={() => customQuestion('behavioral')}>Add Custom Behavioral Question</button>
                    </div>

                    <div className='w-100 file-upload-info d-flex justify-content-center mb-3'>
                        <p className='m-3 p-0'>
                            Overall Notes
                        </p>
                    </div>
                    <div id='d-flex justify-content-center'>
                        <TextareaAutosize
                            type="text"
                            className='interview-bubble mx-auto response-text mb-3'
                            minRows={5}
                            value={args.candidateInfo.overall_notes || ''}
                            onChange={event => handleNotesChange('overall', 'N/A', 0, event)}
                            onBlur={() => args.handleSaveCandidate({...args.candidateInfo})}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DisplayInterview;