import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react';
import { loginRequest } from './auth-config';
import InterviewGPT from './InterviewGPT';
import logo from './assets/creospan_logo_standard.png';
import stairs from './assets/creospan_stairs_01.png';

// propTypes is a development time validation library that helps you catch errors by validating your props with types.
import PropTypes from 'prop-types';

/**
 * A wrapped view component that handles authentication and rendering of InterviewGPT component.
 *
 * @returns {JSX.Element} The wrapped view component.
 */
const WrappedView = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const handleRedirect = () => {
        instance
            .loginRedirect({
                ...loginRequest,
                prompt: 'create',
            })
            .catch((error) => console.log(error));
    }

    
    return (
        <div>
            <div className='App'>
                <div class="header-bar">
                    <div class="branding">
                        <a class="" href="https://creospan.com/">
                            <img class=" preload-me" src={logo} srcset="https://creospan.com/wp-content/uploads/2022/09/creospan_logo_standard.png 310w, https://creospan.com/wp-content/uploads/2022/09/creospan_logo_retina.png 620w" width="310" height="57" sizes="310px" alt="Creospan"/>
                        </a>
                    </div>
                </div>
            </div>
            <AuthenticatedTemplate>
                {activeAccount ? (
                    <InterviewGPT user={activeAccount.name}/>
                ):null}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: '50%'}}>
                    <img src={stairs} alt="Creospan" />
                </div>
                <div style={{ flex: '50%'}} className='signInFlex d-flex justify-content-center align-items-center'>
                    <button className='signInBtn' onClick={handleRedirect}>
                            Sign In
                    </button>
                </div>
            </div>
                <div className='signInFlex d-flex justify-content-center align-items-center'>
                </div>
            </UnauthenticatedTemplate>
        </div>
    )
};

/**
 * Renders the main application component.
 * @param {Object} props - The component props.
 * @param {Object} props.instance - The instance of MsalProvider.
 * @returns {JSX.Element} The rendered component.
 */
const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <WrappedView/>
        </MsalProvider>
    );
};

App.propTypes = {
    instance: PropTypes.object.isRequired,
};

export default App;