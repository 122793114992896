import '../App.css';
import ReactDOM from 'react-dom';
import JDUploadModal from '../modals/JDUploadModal';
import ResumeUploadModal from '../modals/ResumeUploadModal';
import React, {useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import LinkedInModal from '../modals/LinkedInModal';
const backend_key = process.env.REACT_APP_BACKEND_API;

const NewInterview = (args) => {
    const [DescriptionUploaded, SetDescriptionUpload ] = useState(false);
    const [ResumeUploaded, SetResumeUploaded] = useState(false);
    const [JDText, SetJDText] = useState('');
    const [CRText, SetCRText] = useState('');
    
    const handleFileSubmission = () => {
        if((DescriptionUploaded || JDText) && (ResumeUploaded || CRText)){
            args.setLoading(true);
            args.setAIGenLoading(true);
            args.setHome(false);
            
            const fileForm = new FormData();
            DescriptionUploaded ? fileForm.append('jobDescription', DescriptionUploaded) : fileForm.append('jobDescription', JDText);
            ResumeUploaded ? fileForm.append('resumeUpload', ResumeUploaded) : fileForm.append('resumeUpload', CRText);

            let errorData;
        
            fetch('/uploadQA', {
                method: 'POST',
                headers: {
                    'my-custom-header-key': backend_key,
                },
                credentials: 'include',
                body: fileForm,
            })
            .then((response) => {
                console.log('raw response from /uploadQA: ' + JSON.stringify(response));
                response.json();
            })
            .then(async (data) => {
                errorData = data;
                let missing_qualities = '';
                for(let quality of data[2]){
                    missing_qualities += '- ' + quality + '\n\n'
                }
                let general_qs = [];
                let technical_qs = [];
                if(data[0] && data[1]) {
                    for(let q of data[0]){
                        general_qs.push({...q, notes: ''})
                    }
                    for(let q of data[1]){
                        technical_qs.push({...q, notes: ''})
                    }
                }
                let saveData = {
                    uuid: uuidv4(),
                    date: getCurrentDate(),
                    Candidate_Name: data[5].Candidate_Name || 'NOT FOUND' ,
                    Job_Title: data[5].Job_Title || 'NOT FOUND',
                    Job_Description_Title: data[5].Job_Description_Title || 'NOT FOUND',
                    Linkedin_ID: data[5].Linkedin_ID || 'NOT FOUND',
                    general_questions: general_qs || [],
                    technical_questions: technical_qs || [],
                    lacking_qualities: missing_qualities.trim(),
                    strengths: data[6].trim(),
                    score: data[3],
                    assessment: data[4],
                    overall_notes: ''
                };
                let linkedinAssess = await fetchLinkedinAssessment(saveData.Linkedin_ID);
                saveData = {...saveData, linkedCompare: linkedinAssess}
                args.handleSaveCandidate(saveData);
                args.setCandidateInfo(saveData);
                args.setNewInterview(false);
                args.setAIGenLoading(false);
                args.setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                alert("Issue creating Interview. Resume May be too long. Check logs.")
                console.log(errorData);
            });
        }
    }

    const getCurrentDate = () => {
        let date = new Date();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let year = date.getFullYear();
        return `${month}/${day}/${year}`
    }


    
    const fetchLinkedinAssessment = async (linkedinID) => {
        console.log("calling fetchLinkedinAssessment with linkedinID: ", linkedinID);
        if(linkedinID !== 'NOT FOUND'){
            const fileForm = new FormData();
            ResumeUploaded ? fileForm.append('resumeUpload', ResumeUploaded) : fileForm.append('resumeUpload', CRText);
            fileForm.append('linkedinProfile', linkedinID);
            console.log("calling fetchLinkedinAssessment with linkedinID: ", linkedinID);
            let response = await fetch('/assessLinkedin', {
                method: 'POST',
                headers: {
                    'my-custom-header-key': backend_key,
                },
                credentials: 'include',
                body: fileForm,
            })
            let data = await response.json();
            if(data.error){
                console.log("Error data: " + data);
                alert(data.error.message)
                return ''
            }
            if(data.invalid){
                alert(data.invalid)
                return ''
            }
            return data.assessment
        } else {
            let profileId = await getProfileWithModal();
            if(profileId === ''){
                return ''
            } else {
                const fileForm = new FormData();
                ResumeUploaded ? fileForm.append('resumeUpload', ResumeUploaded) : fileForm.append('resumeUpload', CRText);
                fileForm.append('linkedinProfile', profileId);
                let response = await fetch('/assessLinkedin', {
                    method: 'POST',
                    headers: {
                        'my-custom-header-key': backend_key,
                    },
                    credentials: 'include',
                    body: fileForm,
                })
                let data = await response.json();
                if(data.error){
                    alert(data.error.message)
                    return ''
                }
                if(data.invalid){
                    alert(data.invalid)
                    return ''
                }
                return data.assessment
            }
        }
    }

    function getProfileWithModal() {
        return new Promise((resolve) => {
            const usernameInputModal = (
                <LinkedInModal
                    onClose={(profileId) => {
                        resolve(profileId);
                        closeModal();
                    }}
                />
            );
            ReactDOM.render(usernameInputModal, document.getElementById('modal-root'));
        });
    }

    function closeModal() {
        ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'));
    }

    return (
        <>
            <div className='container text-center' style={{"margin-top":"50px"}}>
                <div className='row'>
                    <div className='col'>
                        <h1 style={{color: "black"}}>Job Description</h1>
                        <div className='mt-3'>
                            { DescriptionUploaded ? (
                                <button type="button" className="btn btn-secondary p-3" data-bs-toggle="modal" data-bs-target="#selectFileModalJobDescription">{DescriptionUploaded.name}</button>
                            ):(
                                <button type="button" className="standardButton" data-bs-toggle="modal" data-bs-target="#selectFileModalJobDescription">Upload</button>
                            )}
                        </div>
                        <div>
                            <h4 style={{color: "#8b8d94", 'margin-top': '25px'}}>-- OR --</h4>
                            <p style={{color: "#8b8d94"}}>Paste Job Description Text Below:</p>
                            <textarea className='JDTextArea' value={JDText} onChange={(event) => SetJDText(event.target.value)}></textarea>
                        </div>
                    </div>
                    <div className='col'>
                        <h1 style={{color: "black"}}>Candidate Resume</h1>
                        <div className='mt-3'>
                            { ResumeUploaded ? (
                                <button type="button" className="btn btn-secondary p-3" data-bs-toggle="modal" data-bs-target="#selectFileModalResume">{ResumeUploaded.name}</button>
                            ):(
                                <button type="button" className="standardButton" data-bs-toggle="modal" data-bs-target="#selectFileModalResume">Upload</button>
                            )}
                        </div>
                        <div>
                            <h4 style={{color: "#8b8d94", 'margin-top': '25px'}}>-- OR --</h4>
                            <p style={{color: "#8b8d94"}}>Paste Resume Text Below:</p>
                            <textarea className='JDTextArea' value={CRText} onChange={(event) => SetCRText(event.target.value)}></textarea>
                        </div>
                    </div>
                </div>

                <div>
                    <button type="button" className='standardButton' onClick={handleFileSubmission}>Submit</button>
                </div>
            </div>

            <JDUploadModal SetDescriptionUpload={SetDescriptionUpload}/>
            <ResumeUploadModal SetResumeUploaded={SetResumeUploaded}/>
        </>
    )

}

export default NewInterview;